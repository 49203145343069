
  .reviewspages{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #333;
  
  }

  .aboutpages{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #18b1b7;
  
  }

  .containers{
      background-color: #18b1b7 ;
  }

  .aboutcontainers{
      background-color: #ffff ;
      border-radius: 10px;
  }

  .containers{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    padding-top: 20px;
    border-radius: 10px;
    box-shadow: -1px -1px 2px rgb(255, 255,255,0.25),
    inset -1px -1px 5px rgb(255, 255,255,0.25),
    8px 30px 30px rgb(0,0,0,0.4),
    inset -2px -2px 5px rgb(0,0,0,0.3);
  }
  
  
  .containers h2{
    margin-bottom: 10px;
    font-weight: bolder;
    color:antiquewhite;
  
    
  }
  .containers .skiis
  {
    margin: 10px 0px;
    box-shadow: -1px -1px 2px rgb(255, 255,255,0.25),
    inset -1px -1px 5px rgb(255, 255,255,0.25),
    8px 30px 30px rgb(0,0,0,0.4),
    inset -2px -2px 5px rgb(0,0,0,0.3);
  }
  .containers .skiiss input{
    width: 250px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .containers .skiiss
  {
    margin: 10px 0px;
    box-shadow: -1px -1px 2px rgb(255, 255,255,0.25),
    inset -1px -1px 5px rgb(255, 255,255,0.25),
    8px 30px 30px rgb(0,0,0,0.4),
    inset -2px -2px 5px rgb(0,0,0,0.3);
  }
  .containers .skiiss h3{
    min-width: 300px;
    min-height: 50px;
    font-weight: bold;
    font-size: 20px;
    color:antiquewhite;
    letter-spacing: 1px;
  }
  
  .containers .skiis h3{
    min-width: 300px;
    font-weight: bold;
    font-size: 20px;
    color:antiquewhite;
    letter-spacing: 1px;
  }
  

  .logoclinic{
    height: 50px ;
  }
  .containers .skiis .rating{
    position: relative;
    display: flex;
    justify-content: center;
    margin:10px 0;
    flex-direction: row-reverse;
  
  }
  
  .containers .skiis .rating input{
    position: relative;
    width: 20px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    overflow: hidden;
  }
  
  .containers .skiis .rating input::before{
    content: '\f005';
    position: absolute;
    font-family: fontAwesome;
    font-size: 34px;
    position: absolute;
    left: 4px;
    color: white;
    transition: 0.5s;
  
  }
  .containers .skiis .rating input:nth-child(2n+1)::before{
    right: 4px;
    left: initial;
  }
  .containers .skiis .rating input:hover ~ input::before,
  .containers .skiis .rating input:hover::before,
  .containers .skiis .rating input:checked ~ input::before,
  .containers .skiis .rating input:checked::before{
    color: #e79129 ;
  }

  .buttonreviews{
        background-color: #fce5c9;
  }

  
