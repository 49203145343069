.tablesss th{
    position: sticky;
    top: 0; 
}


.background:nth-child(odd){
    background-color: #97e9ec;
}


