*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Athiti', sans-serif;
    font-weight: bold;
}


h3{
    color: white;
}

li{
    font-size: 28px;
}


.navbar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 75px;
    background-color: #18b1b7;
    color: antiquewhite;
    position: fixed;
    top: 0px;
    left: 0px;
    text-align: center;
    width: 100%;
    z-index: 99 !important;
}


.logo{
    font-size: 30px;
    text-decoration: none;
    color: antiquewhite;
    font-size: 50px;
}


.nav-links{
    display: flex;
    justify-content: flex-end;
    list-style: none;
    width: 75%;

}


.home,
.calendar,
.cancle,
.about,
.login,
.logout,
.signup{
    text-decoration: none;
    color: #333;
    font-size: 20px;
    padding:  10px 20px;
    margin: 0 10px;
    cursor: pointer;
}

.login{
    border-left: 5px solid #0ce193 ;
    border-right: 5px solid #0ce193 ;
    border-radius: 5px;
}
.logout{
    border-left: 5px solid #e79129 ;
    border-right: 5px solid #e79129 ;
    border-radius: 5px;

}

.mobile-menu-icon{
    display: none;

}

@media screen and (max-width: 1800px){
    /* .logo{
        display: flex;
        position: absolute;
        top: 15px;
        left: 35px;
    } */
    .nav-links{
        display: none;
    }

    .nav-links-mobile{
        position: absolute;
        display: block;
        list-style: none;
        background-color: #446c74;
        left: 0;
        top: 75px;
        transition: all 0.5s ease-out;
        width: 100%;
    }
    .home,
    .calendar,
    .cancel,
    .about,
    .login,
    .logout,
    .signup{
        color: white;
        text-align: center;
        padding: 32px;
        width: 100%;
        transition: all 0.5s ease;
    }
    .login{
        border: none;
        border-radius: none;
    }
    .logout{
        border: none;
        border-radius: none;
        
    }

    .mobile-menu-icon{
        display: block;
        position: absolute;
        font-size: 30px;
        color: white ;
        background-color: #1bb3b3;
        border: none;
        outline: none;
        top: 15px;
        right: 25px;
    }

}



