

.react-date-picker__clear-button{
    display: none;
}

.arofattest tr:nth-child(even) {
    background-color: #9cf3f3;
  }

.testtest{
    display: flex;
    justify-content: center;
    align-items: center;
}



.headtime{
    text-align: center;
    width: 5%;
    height: 50px;
    font-size:larger;
}
.headname{
    text-align: center;
    width: 20%;
    height: 50px;
    font-size:larger;
}
.headservice{
    text-align: center;
    width: 15%;
    height: 50px;
    font-size:larger;
}
.headprovider{
    text-align: center;
    width: 5%;
    height: 50px;
    font-size:larger;
}
.headstatus{
    text-align: center;
    width: 20%;
    height: 50px;
    font-size:larger;
}

@media screen and (max-width: 1800px) {

  td .cfer {
    display: none;
  }
  tr .cfer {
    display: none;
  }

  .headname{
    text-align: center;
    width: 40%;
    height: 50px;
    font-size:larger;
}
    .searchapp{
        display: none;
    }

}