* {
  margin: 0 0;
  padding: 0 0;
  box-sizing: border-box;
}

  .content {
    border-top: 2px solid antiquewhite;
    display: grid;
    padding: 20px;
    height: 96vh;
    grid-template-columns: 25% 25%;
    grid-column-gap: 10px;
    grid-row-gap: 45px;
    justify-content: space-evenly;
    align-content: center;
    background-image: url("https://thesocialgrabber.com/wp-content/uploads/2020/09/2317487.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .content a {
    text-decoration: none;
    color: antiquewhite;
    border: 5px solid antiquewhite;
    border-radius: 10px;
    box-shadow: -1px -1px 2px rgb(255, 255, 255, 0.25),
      inset -1px -1px 5px rgb(255, 255, 255, 0.25),
      8px 30px 30px rgb(0, 0, 0, 0.4), inset -2px -2px 5px rgb(0, 0, 0, 0.3);
    border-radius: 10px 10px 10px;
    text-align: center;
    padding: 15px;
    font-size: 30px;
  }
  .content a {
    background-color: #18b1b7;
  }


@media screen and (max-width: 1800px){

  .box{
    display: block;
  }
  .content{
    display: grid;
    grid-template-columns: 90%;
    height: 100vh;
    grid-row-gap: 25px;
  }
  .content a{
    font-size: 20px;
  }
}

/* 
@media screen and (max-width: 376px){

  .box{
    display: block;
  }
  .content{
    display: grid;
    grid-template-columns: 90%;
    height: 550px;
    grid-row-gap: 25px;
  }
  .content a{
    font-size: 20px;
  }
}




@media screen and (max-width: 390px){

  .box{
    display: block;
  }
  .content{
    display: grid;
    grid-template-columns: 90%;
    height: 700px;
    grid-row-gap: 25px;
  }
  .content a{
    font-size: 20px;
  }
}


@media screen and (max-width: 414px){

  .box{
    display: block;
  }
  .content{
    display: grid;
    grid-template-columns: 90%;
    height: 800px;
    grid-row-gap: 25px;
  }
  .content a{
    font-size: 20px;
  }
}
 */
