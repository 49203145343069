.users tr:nth-child(odd) {
  background-color: #97e9ec;
}

.income {
  border-right: 5px solid #397D54;
  border-radius: 5px;
}

.expense {
  border-right: 5px solid #FF202B;
  border-radius: 5px;
}

td .resetusermobile {
    display: none;
  }
  td .editusermobile {
    display: none;
  }
.deletecancel{
    display: flex;
    justify-content: center;
    align-items: center;
}
td .deleteuserss{
    display: none;
}

form .officer{
  display: flex;
  justify-content: center;
  align-items: center;
}

div .officerjob{
  display: flex;
  justify-content: center;
  align-items: center;
}

div .jober{
  margin-top: 30px;
  margin-bottom: 20px;
  width: 35%;
  margin-right: 10px;
  margin-left: 10px;
}

/* style={{ marginTop: "30px",marginBottom: "100px", width: "30%",marginRight:'10px',marginLeft:'10px' }} */

@media screen and (max-width: 1800px) {

  td .deleteuser {
    display: none;
  }
  td .editusercom {
    display: none;
  }
  td .resetusercom {
    display: none;
  }
  .divbutton {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  td .resetusermobile {
    display: contents;
  }
  td .editusermobile {
    display: contents;
  
  }

  form .officer{
    display: block;
    text-align: center;

  }
  .headuserrole {
    display: none;
  }

  .bodyuserrole {
    display: none;
  }

  .headusertel {
    display: none;
  }

  .bodyusertel {
    display: none;
  }

  .headuseraction {
    text-align: center;
    width: 15%;
    height: 50px;
  }

  .bodyuseraction {
    text-align: center;
    width: 15%;
    height: 50px;
  }
  td .deleteusers{
      display: none;
  }
  td .deleteuserss{
      display: contents;
  }

  div .officerjob{
    display: block;
    text-align: center;
  }
  
  div .jober{
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
  }

}
