/* .react-date-picker__clear-button{
    display: none;
} */

/* .testtest{
    display: flex;
    justify-content: center;
    align-items: center;
} */
.note{
  text-align: left;
}

.scheduletop{
  margin: 10px;
}

.scheduletophead{
  font-size: 18px;
}

.scheduletopbody{
  font-size: 18px;
}

.aboutttm {
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-color: #e79129;
  padding: 20px;
  bottom: 60px;
  position: fixed;
  left: 0px;
  width: 100%;
}

.aboutttmmobile {
  display: none;
}

.officerttm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.servicettm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
.locationttm {
  width: 40%;
  margin: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;
}

.search tr:nth-child(odd) {
  background-color: #97e9ec;
}

.searchblock tr:nth-child(odd) {
  background-color: #fce9d2;
}

.searchback .searchbackrow:nth-child(odd) {
  background-color: #fce9d2;
}

.searchback .searchusersrow:nth-child(odd) {
  background-color: #97e9ec;
}

.searchback{
  display: flex;
}


.nextday{
  display: flex;
}


.swal2-container {
  z-index: 99999 !important;
}

.modal {
  z-index: 0 !important ;
}

td,
th {
  border: 1px solid #426974;
  text-align: center;
  padding: 8px;
}

.bodynine{
    background-color: #ccf3f4;
  }
  .bodyten{
    background-color: #efeaea;
  }
  .bodyeleven{
    background-color: #dbd0e7;
  }
  .body13{
    background-color: #daecae;
  }
  .body14{
    background-color: #edde99;
  }
  .body16{
    background-color: #a3d0fa;
  }
  .body17{
    background-color: #edb199;
  }
.head{
    background-color: #18b1b7;
}
.headblock{
    background-color: #e79129;
}

/* .headtime{
    text-align: center;
    width: 10%;
    height: 50px;
    font-size:larger;
} */

.bodytime {
  text-align: center;
  height: 50px;
}

/* .headname{
    text-align: center;
    width: 15%;
    height: 50px;
    font-size:larger;
} */

.bodyname {
  text-align: left;
  height: 50px;
}

/* .headservice{
    text-align: center;
    width: 5%;
    height: 50px;
    font-size:larger;
} */

.bodyservice {
  text-align: center;
  height: 50px;
}

/* .headprovider{
    text-align: center;
    width: 5%;
    height: 50px;
    font-size:larger;
} */

.bodyprovider {
  text-align: center;
  height: 50px;
}

/* 
.headstatus{
    text-align: center;
    height: 50px;
    font-size:larger;
}  */
.bodystatus {
  text-align: center;
  height: 50px;
}

/*

.bodytime,
.bodyservice,
.bodyprovider,
.bodystatus{
    text-align: center;
    font-size:larger;
}


.bodyname{
    padding-left: 4%;
    font-size:larger;
    width: auto;
}  */
  .imgttm{
    height: 350px;
  }
@media screen and (max-width: 1800px) {
  .imgttm{
    height: 220px;
    text-align: center;
  }
  .aboutttm{
    display: none;
  }

  .locationttm {
    width: 100%;
  }

  .note{
    font-size: 8px;
  }


  .aboutttmmobile {
    display: block;
    background-color: #e79129;
    bottom: 60px;
    padding-bottom: 60px;
    padding-top: 10px;
    padding-right: 30px;
    width: 100%;
    left: 0px;
  }

  .servicettm {
    display: block;
    text-align: center;
    width: 100%;
  }
  .officerttm {
    display: block;
    text-align: center;
    width: 100%;
  }

  .scheduletop{
    margin: 10px;
    font-size: 14px;
  }

  .scheduletophead{
    font-size: 10px;
  }

  .scheduletopbody{
    font-size: 8px;
  }
  
   td .btnstatus{
    font-size: 10px;
   } 

  .headservice {
    display: none;
  }


  .bodyservice {
    display: none;
  }
  .bodyprovider {
    font-size: 10px;
  }
  .headprovider {
    font-size: 10px;
  }

  .bodyname {
    text-align: left;
    height: 45px;
    font-size: 10px;
  }

  .headtime{
    text-align: center;
    width: 10%;
    height: 50px;
    font-size: 10px;
}

  .bodytime {
    text-align: center;
    width: 20%;
    height: 50px;
    font-size: 10px;
  }

  .searchback{
    display: block;
  }
  .searchback .searchbackrole{
    display: none;
}
  .searchback .searchbacktn{
    display: none;
}
  .searchback .searchbackprovider{
    display: none;
}



}
