footer{
    display: flex;
    background-color: #3e636e;
    color: white;
    height: 60px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    z-index: 99 !important;
}
.devname{
    display: block;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 8px;
}
.devcradit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 8px;
}

.emailcradit{
    border-left: 1px solid white;
    margin-left: 10px;
    padding-left: 10px;
}